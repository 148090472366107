<template>
  <div class="yuanqu">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>

    <div>
      <div class="titleBj">
        <div class="titleWz">园区介绍</div>
      </div>
    </div>
    <!-- pc端 -->
    <div class="pcShow">
      <div class="one">
        <div class="left">
          <div class="title">北京朝阳燕京雍阳幼儿园 常营校区</div>
          <div class="text">
            <p>
              燕京雍阳常营校区是另一个激发创造力和培养领导者的地方。以丰富多样的教育课程和专业团队为支持，为孩子们提供了一个充满挑战和成长机会的环境。
            </p>
            <p>
              常营校区拥有现代化的设施，包括设备齐全的教室、音乐室、STEM实验室和户外运动场地。我们的教育课程涵盖了美术、音乐、国学传统文化、STEM科学探索等领域，致力于培养孩子的全面素养。
            </p>
            <p>
              特别值得一提的是，常营校区也提供国际象棋、高尔夫、戏剧表演等特色课程，以满足不同孩子的兴趣和需求。在这里，孩子们将体验到学习的乐趣和成就感。
            </p>
            <p>
              常营校区重视家校合作，与家长紧密合作，共同关心孩子的成长和发展。我们坚信通过共同努力，可以为每个孩子打造一个充满希望和机会的未来。
            </p>
          </div>
        </div>
        <div class="right">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/yuanqujieshaoTwo.png"
            style="padding-left: 60px"
          />
        </div>
      </div>
      <div class="two">
        <div class="right">
          <img
            src=" https://yyyey.oss-cn-beijing.aliyuncs.com/yeyDbxqImgPc.png
          "
            style="padding-right: 60px"
          />
        </div>
        <div class="left">
          <div class="title">北京朝阳燕京雍阳幼儿园 东坝校区</div>
          <div class="text">
            <p>
              在燕京雍阳东坝校区，我们致力于为孩子们提供安全、温暖和启发的学习环境。拥有专业的教育团队，注重每个孩子的成长和发展。校区内设有现代化的教室、艺术工作室、音乐室、STEM实验室以及户外活动场地，为孩子们提供了丰富多彩的学习机会。
            </p>
            <p>
              教育课程涵盖了美术、音乐、国学传统文化、STEM科学探索等多个领域，旨在激发孩子的创造力和好奇心。此外，还提供国际象棋、高尔夫、戏剧表演等特色课程，以培养孩子的智力、体能和社交能力。
            </p>
            <p>
              东坝校区注重家园合作，与家长建立紧密的沟通和合作关系，共同关心孩子的成长。我们相信通过教育的力量，可以塑造未来的领袖和创新者。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="phoneShow">
      <div class="jieshaoBox">
        <div class="jieshaoTitle">北京朝阳燕京雍阳幼儿园 常营校区</div>
        <div class="jieshaoImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/yuanqujieshaoTwo.png"
            alt=""
          />
        </div>
        <div class="jieshaoContent">
          <p>
            燕京雍阳常营校区是另一个激发创造力和培养领导者的地方。以丰富多样的教育课程和专业团队为支持，为孩子们提供了一个充满挑战和成长机会的环境。
          </p>
          <p>
            常营校区拥有现代化的设施，包括设备齐全的教室、音乐室、STEM实验室和户外运动场地。我们的教育课程涵盖了美术、音乐、国学传统文化、STEM科学探索等领域，致力于培养孩子的全面素养。
          </p>
          <p>
            特别值得一提的是，常营校区也提供国际象棋、高尔夫、戏剧表演等特色课程，以满足不同孩子的兴趣和需求。在这里，孩子们将体验到学习的乐趣和成就感。
          </p>
          <p>
            常营校区重视家校合作，与家长紧密合作，共同关心孩子的成长和发展。我们坚信通过共同努力，可以为每个孩子打造一个充满希望和机会的未来。
          </p>
        </div>
      </div>

      <div class="jieshaoBox">
        <div class="jieshaoTitle">北京朝阳燕京雍阳幼儿园 东坝校区</div>
        <div class="jieshaoImg">
          <img
            src=" https://yyyey.oss-cn-beijing.aliyuncs.com/yeyDbxqImgPc.png"
            alt=""
          />
        </div>
        <div class="jieshaoContent">
          <p>
            在燕京雍阳东坝校区，我们致力于为孩子们提供安全、温暖和启发的学习环境。拥有专业的教育团队，注重每个孩子的成长和发展。校区内设有现代化的教室、艺术工作室、音乐室、STEM实验室以及户外活动场地，为孩子们提供了丰富多彩的学习机会。
          </p>
          <p>
            教育课程涵盖了美术、音乐、国学传统文化、STEM科学探索等多个领域，旨在激发孩子的创造力和好奇心。此外，还提供国际象棋、高尔夫、戏剧表演等特色课程，以培养孩子的智力、体能和社交能力。
          </p>
          <p>
            东坝校区注重家园合作，与家长建立紧密的沟通和合作关系，共同关心孩子的成长。我们相信通过教育的力量，可以塑造未来的领袖和创新者。
          </p>
        </div>
      </div>
    </div>

    <div class="empty"></div>
    <footerBtm></footerBtm>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "yuanqu",
  components: { headerNav, footerBtm },
  data() {
    return {
      bannerList: [],
    };
  },
  mounted() {
    // banner
    this.api.allPage({ name: "园区介绍" }).then((res) => {});
    this.api.banner({ remark1: 5 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
  },
  methods: {},
};
</script>
<style scoped>
.titleBj {
  width: 1200px;
  height: 110px;
  margin: 0 auto;
  background-image: url(https://lwjy.oss-cn-beijing.aliyuncs.com/school/yuanqujieshaobj.png);
  background-size: 100% 100%;
  margin-top: 30px;
  box-sizing: border-box;
}

.titleWz {
  width: 240px;
  height: 100px;
  font-size: 60px;
  font-weight: 500;
  color: #25ae85;
  line-height: 140px;
  margin: 0 auto;
}

.banner {
  padding-top: 90px;
  width: 1920px;
  height: 750px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.one {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  padding-top: 88px;
}
.two {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  padding-top: 88px;
}
.left {
}
.title {
  width: 614px;
  height: 40px;
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #25ae85;
  line-height: 40px;
}
.text {
  width: 703px;
  height: 304px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 32px;
  padding-top: 40px;
}
.right {
}

.pcShow {
  display: block;
}
.phoneShow {
  display: none;
}
.empty {
  height: 223px;
}
@media screen and (max-width: 1440px) {
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  div /deep/ .el-carousel__container {
    height: 563px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  .titleBj{
    width: 910px;
    height: 100px;
    margin-top: 57px;
  }
  .titleWz{
    width: 910px;
    height: 100px;
    font-size: 43px;
    text-align: center;
  }
  .title{
    width: 500px;
    font-size: 28px;
  }
  .one{
    width: 911px;
    align-items: center;
  }
  .two{
    width: 911px;
  }
  .text{
    width: 500px;
    height: auto;
    padding-top: 28px;
    font-size: 12px;
    font-weight: bold;
    line-height: 23px;
  }
  .left{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .right img{
    width: 370px;
    height: 285px;
    padding-left: 43px !important;
  }

  .empty{
    height: 160px;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  .titleBj{
    width: 853px;
    height: 93px;
    margin-top: 57px;
  }
  .titleWz{
    width: 853px;
    height: 93px;
    font-size: 40px;
    text-align: center;
  }
  .title{
    width: 470px;
    font-size: 28px;
  }
  .one{
    width: 853px;
    align-items: center;
    padding-top: 59px;
  }
  .two{
    width: 853px;
  }
  .text{
    width: 470px;
    height: auto;
    padding-top: 28px;
    font-size: 12px;
    font-weight: bold;
    line-height: 21px;
  }
  .left{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .right img{
    width: 347px;
    height: 267px;
    padding-left: 40px !important;
    border-radius: 13px;
  }

  .empty{
    height: 150px;
  }
}


@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }

  .pcShow {
    display: none;
  }
  .phoneShow {
    display: block;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .titleBj {
    width: 100%;
    height: 47px;
    margin-top: 20px;
  }
  .titleWz {
    width: 100%;
    height: 47px;
    font-size: 20px;
    line-height: 67px;
    margin-top: 16px;
    box-sizing: border-box;
    text-align: center;
  }
  .jieshaoBox {
    width: 100%;
    margin-top: 30px;
  }
  .jieshaoBox .jieshaoTitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
    margin-bottom: 24px;
  }
  .jieshaoBox .jieshaoImg {
    width: 343px;
    height: 264px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .jieshaoBox .jieshaoImg img {
    width: 100%;
    height: 100%;
  }
  .jieshaoContent {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 18px;
  }
  .jieshaoContent p {
    margin-bottom: 15px;
  }
  .empty {
    height: 44px;
  }
}
</style>
